/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import useReactRouter from 'use-react-router'
import Webcam from "react-webcam"
import { Modal } from 'react-bootstrap'
import moment from 'moment-timezone'
import {
  getStudioByUri,
  getOneSession,
  onboardUser,
  uploadImage,
  static_root,
  temp_root,
  getUserById,
  getUser,
  api_host,
  directUpload,
  addVideoEntry,
  updateUserFields,
  updateAppointmentOneField
} from '../../services'
import { dataURLtoFile, mobileSafariCheck, mobileChromeCheck, copyUrl, injectIntercom } from '../../utils'
import { RoleEditor } from '../CheckinList'
import { NotificationComponent } from '../../App'
import { USER_TYPES } from '../../constants'
import AuthPage from '../Auth/Page'
import { FaArrowLeft, FaExclamationTriangle, FaLink, FaPlayCircle, FaSpinner, FaTrashAlt } from 'react-icons/fa';
import InfoCard from './components/InfoCard'
import EditInfo from './components/EditInfo'
import useProfile from "../TalentPage/hooks/use-profile";
import { CautionIcon } from '../../assets/icons/session'
import { useLocation } from 'react-router-dom';
import './style.scss'


const Onboard = ({ history }) => {
  const [user, setUser] = useState(null)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [sagNumber, setSagNumber] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [apiResult, setApiResult] = useState({})
  const { match } = useReactRouter();
  const studio_uri = match.params.uri
  const session_id = match.params.session_id
  const [studio, setStudio] = useState(null)
  const [session, setSession] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [avatar64, setAvatar64] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [cameraError, setCameraError] = useState(mobileSafariCheck() || mobileChromeCheck())
  const [loading, setLoading] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const [noteToCastingDirector, setNoteToCastingDirector] = useState('')

  const [agent, setAgent] = useState('')
  const [actualCall, setActualCall] = useState(new Date())
  const [interviewNo, setInterviewNo] = useState(1)
  const [role, setRole] = useState('')
  const [optIn, setOptIn] = useState(false)

  const [roles, setRoles] = useState([])
  const [isMobileSafari, setIsMobileSafari] = useState(false)
  const [isAppFrame, setIsAppFrame] = useState(false)

  const [showTermsModal, setShowTermsModal] = useState(false)
  const [showChoice, setShowChoice] = useState(true)

  const [videos, setVideos] = useState([])
  const [previewVideo, setPreviewVideo] = useState(null)
  const [videoRequired, setVideoRequired] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({ fileCount: '', progress: '' })
  const [photos, setPhotos] = useState([])
  const [showEditInfo, setShowEditInfo] = useState(false)
  const [agentEmail, setAgentEmail] = useState('')
  const [agentPhone, setAgentPhone] = useState('')
  const [ft, setFt] = useState('')
  const [inches, setInches] = useState('')
  const [instagramHandle, setInstagramHandle] = useState('')
  const [tiktokHandle, setTikTokHandle] = useState('')
  const webcamRef = useRef(null)
  const [talentIsAllowedToCreateRoles, setTalentIsAllowedToCreateRoles] = useState(false)
  const [showNoticeForMobile, setShowNoticeForMobile] = useState(false)
  const [opportunityIdList, setOpportunityIdList] = useState([])
  const [selectedOpportunityId, setSelectedOpportunityId] = useState('')
  const [isKiosk, setIsKiosk] = useState(false)
  const [authPageVisible, setAuthPageVisible] = useState(false)
  const [clothesType, setClothesType] = useState()
  const [sizeObject, setSizeObject] = useState(null);

  const { userAppointments } = useProfile();
  const appointment = userAppointments.find(appointment => appointment?.session?._id === session?._id);

  const onboardUrl = window.location.origin + window.location.pathname
  window.sessionStorage.setItem('onboard_url', onboardUrl)

  
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isKiosk = queryParams.get('kiosk');
    setIsKiosk(isKiosk)
  }, [location.search])

  useEffect(() => {
    if (isKiosk) {
      setShowEditInfo(true)
    }
  }, [isKiosk])

  useEffect(() => {
    setShowChoice(!window.localStorage.getItem('token'))
    setIsAppFrame(window.is_react_native)
    setIsMobileSafari(mobileSafariCheck())
    if (!window.is_react_native) {
      const urlParams = new URLSearchParams(window.location.search)
      if (!window.localStorage.getItem('token') && urlParams.get('token')) {
        window.localStorage.setItem('token', urlParams.get('token'))
        window.location.reload(true)
      }
    }
    const u = getUser()
    if (u) {
      setUserLoading(true)
      getUserById(u.id).then(data => {
        injectIntercom(data, false)
        setUser(data)
        setFirstName(data.first_name || '')
        setLastName(data.last_name || '')
        setAgent(data.agent || '')
        setPhoneNumber(data.phone || '')
        setEmail(data.email)
        setOptIn(data.opt_in)
        setPhotos(data.photos || [])
        setAgentEmail(data.agent_email || '')
        setAgentPhone(data.agent_phone || '')
        setInstagramHandle(data.instagram_handle || '')
        setTikTokHandle(data.tiktok_handle || '')

        if (data.height) {
          const height = JSON.parse(data.height);
          setFt(height.ft || '')
          setInches(height.inches || '')
        }
        if(data.clothes_type){
          setClothesType(data.clothes_type)
        }
        if(data.clothes_size_object){
          const clothes_size_object = JSON.parse(data.clothes_size_object);
          setSizeObject(clothes_size_object)
        }
        setUserLoading(false)
      })
    } else {
      injectIntercom(null, false)
    }
  }, [])

  useEffect(() => {
    if (window.is_react_native && isMobileSafari) {
      setCameraError(true)
    }
  }, [isMobileSafari])

  useEffect(() => {
    const process = async () => {
      setLoading(true)
      const studio = await getStudioByUri(studio_uri)
      const session = await getOneSession(session_id)
      document.title = `${studio.name} Check In`;
      if (studio && studio._id) { setStudio(studio) }
      if (session && session._id) { setSession(session) }
      setLoading(false)
    }
    process()
  }, [studio_uri, session_id])

  useEffect(() => {
    setRoles(studio?.project_roles)
    setTalentIsAllowedToCreateRoles(studio?.allow_talent_create_role)
    if(studio?.limelite_project_id){
      setOpportunityIdList(studio?.limelite_project_role_ids)
    }
   
  }, [studio])

  useEffect(() => {
    if(appointment?.role){
      setRole(appointment?.role)
    }
  }, [appointment])

  useEffect(() => {
    if (user && user.logo) {
      window.localStorage.removeItem('prev_url')
    }
  }, [user])

  useEffect(() => {
    if (!window.is_react_native) {
      setShowNoticeForMobile(mobileChromeCheck() || mobileSafariCheck())
    }
  }, [])

  useEffect(() => {
    if(user && !user.first_name){
      setShowEditInfo(true)
    }
  }, [loading, user])

  const logout = () => {
    window.localStorage.removeItem('token')
    window.location.reload(true)
  }

  const showAuthPage = () => {
    return (
      <AuthPage
        history={history}
        talentOnly={true}
        afterSuccess={() => {
          window.localStorage.setItem('prev_url', window.location.href)
          window.location.href = window.location.href
        }}
        projectInfo={{studio:studio?.name, session:session?.name}}
      />
    )
  }

  if (loading || userLoading) {
    return (
      <div className='message-alert'>
        <FaSpinner size='32px' className='spinning' />
        <h4>Loading...</h4>
      </div>
    )
  }

  if ((!user && !isKiosk) || authPageVisible) {
    return showAuthPage()
  }

  if (user && user.user_type !== USER_TYPES.TALENT) {
    return (
      <div className='message-alert'>
        <FaExclamationTriangle size={32} />
        <h5>
          Your email ({user.email}) is not linked to a talent account. To continue, please create a talent profile. If you'd like to use this email, you can delete your current account by clicking the dropdown in the top right corner, then sign up again as a talent account.
        </h5>
        <a href="#" onClick={() => {
          logout()
        }}>Logout</a>
      </div>
    )
  }


  if (!studio) {
    return (
      <div className='message-alert'>
        <h4>No Studio found</h4>
      </div>
    )
  }
  if (!session) {
    return (
      <div className='message-alert'>
        This link is invalid. Try copying the link again and make sure there are no extra characters at the end. Please email <a href="mailto:hello@heyjoe.io">hello@heyjoe.io</a> if you still have trouble.
      </div>
    )
  }
  if (session.is_deleted) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <span className="display-1">404</span>
      </div>

    )
  }

  const onboardInTime = session.dates.find(d => {
    if (d.location_type === "Video Submission") {
      const currentDate = moment.tz(new Date(), 'America/Los_Angeles').startOf('day');
      const sessionDate = moment.tz(new Date(d.start_time), 'America/Los_Angeles').startOf('day');
      return currentDate.isSameOrBefore(sessionDate);
    }
    return moment.tz(new Date(), 'America/Los_Angeles').format('YYYY-MM-DD') === moment.tz(new Date(d.start_time), 'America/Los_Angeles').format('YYYY-MM-DD')
  })
  if (!onboardInTime) {
    return (
      <div className='d-flex flex-column text-center pt-5 wrong-time vh-100 onboard-container'>
        <img
          className="logo d-block mx-auto"
          src={static_root + studio.logo}
          alt={studio.name}
        />
        <h3 className="brand mt-4 mb-3 mx-auto">Welcome to {studio.name}/{session.name} Virtual Check In</h3>

        <label className='h3 my-5'>Uh-oh</label>
        <p className='h5 mx-5'>
          Hello! This session is scheduled for {session.dates.map(d => moment(d.start_time).format('MM/DD/YYYY'))}. Please come back on the scheduled date. If you feel you received this message in error, please contact {studio.casting_directors.map(d => {
            return `${d.first_name} ${d.last_name} (${d.email})`
          }).join(' or ') || 'hello@heyjoe.io'} who is scheduling the session
        </p>
      </div>
    )
  }

  if (showMessage) {
    return (
      <div className="message-alert">
        {(apiResult || {}).message || 'Process finished.'}
      </div>
    )
  }

  if (showAlert) {
    if (onboardInTime.location_type === "Video Submission") {
      return (
        <div className="message-alert">
          <h3>
            Thank you for your submission to {studio.name}! The Casting Director will reach out if anything additional is required.
          </h3>
          <h5>Thank you, The Hey Joe Team</h5>
        </div>
      )
    }
    if(isKiosk){
      setTimeout(() => {
        logout()
      }, 1000)
      return (
        <div className="message-alert">
          <h4 className='h-32-400'>
            Check in complete!<br />
          </h4>
          Redirecting...
          <FaSpinner size='32px' className='spinning' />
      </div>
      )
    } else{
      window.location = `/message/${apiResult.record._id}`
      return (
        <div className="message-alert">
          Thank you {firstName} {lastName}. You are successfully checked in to {studio.name}. We will send a text shortly.
        </div>
      )
    }
  }

  if (submitting) {
    return (
      <div className="message-alert">
        <FaSpinner size='32px' className='spinning' />
        <h4>
          Processing...<br />
          {onboardInTime?.location_type === 'Video Submission'
            ? "Please wait while videos are uploading"
            : "Please wait for a moment"
          }
        </h4>
        {videos.length > 0 && (
          <div>
            <h4>{uploadProgress.fileCount || 0} of {videos.length} files uploaded</h4>
            <h5>{uploadProgress.progress === 100 ? '..Processing File..' : `Uploading ${uploadProgress.progress || 0}%`}</h5>
          </div>
        )}
      </div>
    )
  }


  let timeOptions = []
  let time = moment().startOf('day')
  const endDayTime = moment().endOf('day')
  while (true) {
    time = time.add(5, 'minutes')
    if (endDayTime.diff(time) < 0) {
      break
    }
    timeOptions.push({
      value: time.toDate(),
      text: time.format('hh:mm a')
    })
  }


  const takePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    if (imageSrc && imageSrc.includes(',')) {
      const file = dataURLtoFile(imageSrc, `${new Date()}.jpg`)
      setAvatarImg(file)
    }
  }

  const isValidPhoneNumber = (phNumber) => {
    const phoneNumberRegex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
    return phoneNumberRegex.test(phNumber);
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  }

  const validateProfileInfo = () => {
    if (!firstName) {
      window.alert('Please add your First name!')
      return false
    }
    if (!lastName) {
      window.alert('Please add your Last name!')
      return false
    }
    if(!phoneNumber || phoneNumber === "undefined"){
      window.alert('Phone number required!')
      return false
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      window.alert('Please Enter a valid Phone number')
      return false
    }
    if(!email){
      window.alert('Email required!')
      return false
    }
    if (!isValidEmail(email)) {
      window.alert('Please Enter a valid Email')
      return false
    }
    if (agentPhone && !isValidPhoneNumber(agentPhone)) {
      window.alert('Please Enter a valid Agent Phone number')
      return false
    }
   
    return true
  }

  const updateUser = async () => {

    try {
      const fields = {
        agent,
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        agent_phone: agentPhone
      };
      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
      photos.forEach((p) => {
        formData.append("photos", p);
      });

      await updateUserFields(user._id, formData);
      
      setShowEditInfo(false);
    } catch (e) {
      console.log('updateUser error', e)
    }
  };

  const onSubmit = async (ev) => {
    ev.preventDefault()
    if (onboardInTime?.location_type === 'Video Submission' && videos.length === 0) {
      setVideoRequired(true)
      return
    }
    setVideoRequired(false)

    const v = validateProfileInfo()
    if (!v) {
        return
    }
    if(showEditInfo){
      await updateUser()
    }
    
    if (!role) {
      window.alert('Please add a Role')
      return
    }
    if (!avatar64) {
      window.alert('Please capture/upload a photo of yourself for our log sheet')
      return
    }
    setSubmitting(true)

    const videoEntriesToAdd = []
    if (onboardInTime.location_type === 'Video Submission') {
      try {
        for (let i = 0; i < videos.length; i++) {
          const res = await directUpload(videos[i].file, studio.jitsi_meeting_id, ('' + Math.random()).slice(2, 7), (progress) => {
            setUploadProgress({
              fileCount: i,
              progress: parseInt(progress)
            })
          })
          videoEntriesToAdd.push(res.Key)
        }
      } catch (err) {
        console.log(err)
        window.alert("Your upload has failed. Please refresh this page and try again")
        setSubmitting(false)
        return
      }
    }

    onboardUser({
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phoneNumber,
      sagnumber: sagNumber,
      session: session._id,
      agent: agent,
      actual_call: actualCall,
      interview_no: interviewNo,
      role: role,
      limelite_role_id: selectedOpportunityId,
      avatar: avatar64,
      opt_in: optIn,
      note_to_casting_director: noteToCastingDirector,
      browser_info: `${window.navigator.userAgent} - ${window.navigator.platform} - ${window.navigator.appVersion}`,
      video_submission: onboardInTime?.location_type === 'Video Submission',
      location_type: onboardInTime?.location_type,
      photos,
      height: { ft, inches },
      agent_email: agentEmail,
      agent_phone: agentPhone,
      instagram_handle: instagramHandle,
      tiktok_handle: tiktokHandle,
      clothes_type: clothesType,
      clothes_size_object: JSON.stringify(sizeObject)
    }).then(async result => {
      setApiResult(result)
      if (result.record && result.record._id) {
        try {
          if(appointment && appointment._id){
            await updateAppointmentOneField(session_id, appointment.day, appointment._id, {is_checked_in: true})
          }
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'onboard', talent: result.record, studio, session }))
          }
        } catch (err) {
          console.log('IGNORE: react native send info failed.', err)
          setApiResult({
            message: err
          })
        }

        for (let videoKey of videoEntriesToAdd) {
          await addVideoEntry(videoKey, result.group_id, session._id, 'thumb-' + videoKey).then(console.log)
        }

        setShowAlert(true)
      } else {
        setShowMessage(true)
      }
    })
  }

  const setAvatarImg = async (file) => {
    const stime = + new Date()
    setUploading(true)
    const res = await uploadImage(file)
    setAvatar64(res.name)
    const duration = +new Date() - stime
    setTimeout(() => {
      setUploading(false)
      // hack around waiting content download time.
      // assume download takes same amount of time as upload
    }, duration)
  }

  const handleVideoSelect = (ev) => {
    if (videoRequired) {
      setVideoRequired(false)
    }
    const vs = [...videos]

    for (let i = 0; i < ev.target.files.length; i++) {
      vs.push({
        file: ev.target.files.item(i),
        src: URL.createObjectURL(ev.target.files.item(i))
      })
    }
    setVideos(vs)
  }

  return (
    <div className='page-wrap'>
      <div className="onboard-container">
        {window.is_react_native && (
          <div className='d-flex mb-2'>
            <button className='btn btn-text btn-sm text-danger' onClick={() => { setShowChoice(true) }}>
              <FaArrowLeft />
            </button>
            <button className='btn btn-text btn-sm text-danger ml-auto' onClick={() => {
              try {
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'leave' }))
                }
              } catch (err) {
                console.log('IGNORE: react native send info failed.', err)
              }
            }}>
              Leave
            </button>
          </div>
        )}
        <div className='d-flex align-items-center'>
          <img
            className="logo"
            src={static_root + studio.logo}
            alt={studio.name}
          />
          <div className='ml-3'>
            <h2 className="h-24-600 ls-2 mt-3 mb-2">Welcome to {studio.name}{session.name ? `/${session.name}` : ''}</h2>
            <h4 className='h-14-400 ls-5 mb-3'>Check in to the session below.</h4>
          </div>
        </div>
        { (isKiosk && !user) && (
          <div className='d-flex align-items-center justify-content-between'>
            <h3 className="h-20-600 ls-2 mt-3 mb-2">
              <button onClick={() => setAuthPageVisible(true)} className='sign-in-btn underline h-20-600 p-0 mr-2'>Sign in</button> 
              Or Check In as Guest
            </h3>
          </div>
        )}

        {showEditInfo ?
          <EditInfo
            setFirstName={setFirstName}
            firstName={firstName}
            setLastName={setLastName}
            lastName={lastName}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            setAgent={setAgent}
            agent={agent}
            setEmail={setEmail}
            email={email}
            setPhotos={setPhotos}
            photos={photos}
            setShowEditInfo={setShowEditInfo}
            setAgentPhone={setAgentPhone}
            agentPhone={agentPhone}
            validateProfileInfo={validateProfileInfo}
            updateUser={updateUser}
            isKiosk={isKiosk} 
            clothesType={clothesType}
            setClothesType={setClothesType}
            sizeObject={sizeObject}
            setSizeObject={setSizeObject}
            setFt={setFt}
            ft={ft}
            setInches={setInches}
            inches={inches} />
          : (
            <InfoCard
              firstName={firstName}
              lastName={lastName}
              phoneNumber={phoneNumber}
              agent={agent}
              photos={photos}
              setShowEditInfo={setShowEditInfo}
              agentPhone={agentPhone} />
          )}
        <div className="">
          <div className='mb-3'>
            <label className='h-12-600'>Role *</label>
            <RoleEditor
              selectedRecord={{ role: role ? role : '' }}
              roles={roles}
              setRole={text => {
                setRole(text)
              }}
              talentIsAllowedToCreateRoles={talentIsAllowedToCreateRoles}
              role={role}
              opportunityIdList={opportunityIdList}
              setSelectedOpportunityId={setSelectedOpportunityId}
            />
          </div>
          <label className='h-12-600'>Photo From Today *</label>
          <div className="checkin-avatar-choose">
            <div className='left'>
              {cameraError || avatar64 ?
                <img src={avatar64 ? `${temp_root}tmp/${avatar64}` : require('../../assets/camera.png')} alt='from today' />
                :
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  forceScreenshotSourceSize
                  mirrored
                  screenshotFormat="image/jpeg"
                  onUserMediaError={(err) => {
                    console.log(err)
                    setCameraError(true)
                  }}
                  videoConstraints={{
                    width: 4000,
                    height: 4000,
                    facingMode: "user"
                  }}
                  className="camera-wrapper"
                />
              }
            </div>
            <div className='right d-flex flex-column justify-content-center'>
              <input
                type="file"
                id="today_photo"
                accept="image/*"
                onChange={ev => setAvatarImg(ev.target.files[0])}
              />
              {uploading && <div className="uploading h-12-600">
                Uploading ...</div>}

              <div className="d-flex justify-content-center flex-column">
                {!cameraError && (!avatar64 ?
                  <button className="primary-outline-button h-12-600" onClick={takePhoto}>
                    Take Photo
                  </button>
                  :
                  <button className="primary-outline-button h-12-600" onClick={() => {
                    setAvatar64(null)
                  }}>
                    Retake Photo
                  </button>
                )}
                <button className="primary-outline-button h-12-600 mt-2" onClick={() => {
                  document.querySelector('#today_photo').click()
                }}>
                  Upload Photo
                </button>
              </div>
            </div>
          </div>


          <div className="contact">
            <form id="contactForm" onSubmit={onSubmit}>
              <div className="full">
                <h3 className='h-16-600 ls-2 mb-3'>SAG-AFTRA Information (If Union Project)</h3>
                <label className='h-12-600 ls-2'>SAG Aftra Number</label>
                <input
                  value={sagNumber}
                  onChange={ev => setSagNumber(ev.target.value)}
                  type="text"
                  name="sagNumber"
                  id="sagNumber"
                  className="form-control"
                />
              </div>
              <div>
                <label className='h-12-600 ls-2'>Interview Number</label>
                <select
                  value={interviewNo}
                  onChange={ev => setInterviewNo(ev.target.value)}
                  name="interviewNo"
                  id="interviewNo"
                  className="form-control"
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
              {onboardInTime?.location_type !== 'Video Submission' && (
                <div>
                  <label className='h-12-600 ls-2'>Actual Call</label>
                  <select
                    value={actualCall}
                    onChange={ev => setActualCall(ev.target.value)}
                    name="actualCall"
                    id="actualCall"
                    className="form-control"
                  >
                    {timeOptions.map(time => (
                      <option
                        key={time.value}
                        value={time.value}
                      >{time.text}</option>
                    ))}
                  </select>
                </div>
              )}
              <div className='full'>
                {onboardInTime?.show_to_talent && onboardInTime?.script_pdf && onboardInTime?.script_pdf.length > 0 && (
                  <div className='mb-3'>
                    <strong>Project Script</strong>
                    {onboardInTime?.script_pdf.map((doc, idx) => (
                      <div key={doc} className='cursor-pointer mr-2' onClick={() => {
                        window.open(api_host + '/s3/' + doc)
                      }}>
                        <FaLink />
                        <span className='ml-1'>Script {idx + 1}</span>
                      </div>
                    ))}
                  </div>
                )}
                {onboardInTime?.show_description_to_talent && onboardInTime?.description && (
                  <div className='mb-3'>
                    <strong>Audition Notes</strong>
                    <div dangerouslySetInnerHTML={{ __html: onboardInTime?.description }} />
                  </div>
                )}

                {onboardInTime?.location_type === 'Video Submission' && (
                  <div className='video-submission'>
                    <input
                      type='file' accept="video/*" id="new-video-input"
                      multiple
                      onChange={ev => {
                        handleVideoSelect(ev)
                      }}
                    />
                    <button className='add-new-video-btn btn btn-primary' onClick={(ev) => {
                      ev.preventDefault()
                      document.querySelector('#new-video-input').click()
                    }}>
                      Add Videos
                    </button>

                    {videos.length > 0 && (
                      <div className='videos-wrapper'>
                        {videoRequired && <div className='text-danger'>Please add videos</div>}
                        {videos.map((v, i) => (
                          <div key={v.src} className='video-item'>
                            <span className='cursor-pointer' onClick={() => { setPreviewVideo(v) }}>
                              <FaPlayCircle size='16px' />
                            </span>
                            <span className='video-filename'>{v.file.name}</span>
                            <span className='cursor-pointer' onClick={() => {
                              setVideos(videos.filter((vi, idx) => idx !== i))
                            }}>
                              <FaTrashAlt size='16px' />
                            </span>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className='mt-3'>
                      <label>Submission Note</label>
                      <textarea name="noteToCastingDirector" value={noteToCastingDirector} onChange={ev => {
                        setNoteToCastingDirector(ev.target.value)
                      }} />
                    </div>
                  </div>
                )}
                <div className="full mt-2">
                  <label className="d-flex align-items-center full">
                    <input type="checkbox" className="mr-2 w-auto" checked readOnly required />
                    I agree to &nbsp;<a target="_blank" onClick={() => {
                      if (window.is_react_native) {
                        setShowTermsModal(true)
                      } else {
                        window.open("https://heyjoe.io/terms-and-conditions/", '_blank')
                      }
                    }}>terms of service</a>
                  </label>
                </div>
                <div className="full d-flex mt-2">
                  <button className='h-14-700 primary-solid-button mt-3 ml-auto' type="submit" disabled={submitting}>
                    {!isKiosk && (showEditInfo ? 'Save and Continue to the Session' : 'Continue to the Session')}
                    {isKiosk && 'Check In'}
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>


        <NotificationComponent
          notificationField="client_notice"
          notificationUpdateAtField="client_notice_updated_at"
        />

        <Modal
          size="xl"
          centered
          show={!!previewVideo}
          onHide={() => {
            setPreviewVideo(false)
          }}
        >
          <Modal.Header closeButton>
            {previewVideo?.file?.name}
          </Modal.Header>
          <Modal.Body className='text-center'>
            <video className='mw-100' src={previewVideo?.src} controls style={{ maxHeight: '80vh' }} />
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-danger' onClick={() => {
              setVideos(videos.filter(v => v.src !== previewVideo.src))
              setPreviewVideo(null)
            }}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          className='h-100'
          show={showTermsModal}
          onHide={() => {
            setShowTermsModal(false)
          }}
        >
          <Modal.Body>
            <iframe src="https://heyjoe.io/terms-and-conditions/" className='onboard-terms-frame' />
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-danger' onClick={() => {
              setShowTermsModal(false)
            }}>
              Done
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="xl"
          centered
          show={showNoticeForMobile}
          onHide={() => {
            setShowNoticeForMobile(false)
          }}
          className='urgent-notice-modal'
        >
          <Modal.Header closeButton>
            <div className='d-flex align-items-center'>
              <CautionIcon />
              <h1 className="mb-0 mx-3 h-16-600">
                4/24/24 URGENT NOTICE
              </h1>
            </div>
          </Modal.Header>
          <Modal.Body>
            <p className="h-14-400 lh-150">
              If you are auditioning from a <strong>mobile device (phone or tablet) after April 24, 2024,</strong> you <strong>MUST UPDATE</strong> the Hey Joe app in the&nbsp; 
                <a className='link' href='https://apple.co/3grIxwR' rel="noopener noreferrer" target='_blank'>Apple</a> or&nbsp;<a className='link' href='https://bit.ly/2MLDLwL' rel="noopener noreferrer" target='_blank'>Google</a> Play store.
            </p>
            <p className="h-14-400">Please email <a className='h-14-600 text-secondary' href="mailto:hello@heyjoe.io">hello@heyjoe.io</a> with any questions or issues updating the app.</p>

          </Modal.Body>
          <Modal.Footer>
            <button className='ok-btn h-14-600' onClick={() => {
              setShowNoticeForMobile(false)
            }}>
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default Onboard
